<template>
  <div>
    <vx-card card-background="primary" content-color="white" title-color="white" title="Cada miembro de RedGirasol es único." class="mb-5" >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>
            En este apartado podrás consultar la información que te identifica, tu alias y correo electrónico.
            ¡Nos llenas de energía al saber que formas parte de nuestra comunidad!
          </p>
        </div>
      </div>
    </vx-card>
    <!-- ALIAS CARD -->
    <vx-card title="Alias" class="mt-base" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Tu alias es la manera en la que podrás identificarte en la Red. No podrá cambiarse o actualizarse una vez definido.</p>
          <div class="vx-row mb-2">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                :label="hasAlias ? '' : 'Ingresa el alias que desees utilizar'"
                type="text"
                v-model.lazy="alias"
                v-validate="'required'"
                :danger="hasError('alias') && sent"
                :danger-text="errorText('alias')"
                :success="isSuccess('alias')"
                :disabled="hasAlias"
                name="alias"/>
            </div>
          </div>
          <!-- Save Button -->
          <div class="vx-row mt-3" v-if="!hasAlias">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button class="mt-2" @click.stop="saveAliasRequest">Agregar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END ALIAS CARD -->
    <!-- EMAIL CARD -->
    <vx-card title="Correo electrónico" class="mt-base" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Tu correo electrónico es otro medio importante para identificarte,
            además de que es el canal en donde más comunicación tendrás con la Red.
            Puedes consultarlo o actualizarlo aquí cuando lo necesites.</p>
          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                type="text"
                v-model.lazy="email"
                v-validate="'required|email'"
                :danger="hasError('email')"
                :danger-text="errorText('email')"
                :success="isSuccess('email')"
                :disabled="!editEmail"
                name="email"/>
            </div>
          </div>
          <!-- Edit Button -->
          <div class="vx-row" v-if="!editEmail" >
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button @click="checkEditEmail">Actualizar</vs-button>
              </div>
            </div>
          </div>
          <!-- Save/cancel Button -->
          <div class="vx-row" v-if="editEmail" >
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button @click="editEmailRequest">Guardar</vs-button>
                <vs-button class="ml-6" type="border" @click="cancelEditEmail">Cancelar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END EMAIL CARD -->
    <!-- CLOSE ACCOUNT CARD -->
    <vx-card class="mb-5" title="Cerrar mi cuenta." v-if="isMounted">
      <div class="vx-row" v-if="!userHasCancellationRequest">
        <div class="vx-col lg:w-3/4 w-full">
          <p class="mb-5">En este apartado podrás cerrar tu cuenta en RedGirasol.
            Recuerda que para hacerlo tendrás que haber concluido cualquier compromiso de inversión, es decir,
            que al momento de cerrarla no tengas ningún pago pendiente, ni saldo disponible en tu cuenta.
            ¡Esperamos que nunca te desconectes de la Red!</p>
          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button type="border" color="dark" @click.stop="closeAccountRequest">Cerrar cuenta</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row" v-else >
        <div class="vx-col w-full">
          <p class="mb-4 bold">Tu cuenta está en proceso de ser cerrada. </p>
          <p>Realizaste la solicitud de cierre el <span class="bold"> {{userCancellationDate}}</span>.</p>
          <p class="mb-5">Pasados 30 días, el acceso a tu cuenta se revocará, específicamente el día <span class="bold"> {{userCancellationLimit}}</span>.</p>
          <p class="mb-5">Si deseas mantener tu cuenta con nosotros, limpiar el planeta y seguir impulsando la transición energética de México
            da clic en el botón que se encuentra a continuación. ¡No te desconectes!</p>
          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button type="border" color="dark" @click.stop="revokeAccountCancellation">Deseo continuar en la Red</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END CLOSE ACCOUNT CARD -->
    <!-- model popup -->
    <vs-popup
      title="Confirma tu alias"
      :active.sync="showPasswordFormPrompt">
      <div class="con-exemple-prompt">
        <p class="mb-5 bold">{{modelFormPromptIndication}}</p>
        <p class="mb-5">Tu alias será: <strong>{{alias}}</strong></p>
        <vs-input
          class="w-full mb-5"
          label="Contraseña"
          type="password"
          v-model.lazy="alias_pwd"
          v-validate="'required'"
          :danger="hasError('alias_pwd')"
          :danger-text="errorText('alias_pwd')"
          :success="isSuccess('alias_pwd')"
          name="alias_pwd"/>
        <vs-button :disabled="!completeModelForm || $isLoading" @click="doUpdateAlias" color="primary" class="mr-5 mt-2">Enviar</vs-button>
        <vs-button @click="closeModelFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cancelar</vs-button>
      </div>
    </vs-popup>
    <vs-popup
      title="Confirma tus credenciales"
      :active.sync="showPasswordEmailFormPrompt">
      <div class="con-exemple-prompt">
        <p class="mb-5 bold">{{modelFormPromptIndication}}</p>
        <p class="mb-5">Tu correo electrónico será: <strong>{{email}}</strong></p>
        <vs-input
          class="w-full mb-5"
          label="Contraseña"
          type="password"
          v-model.lazy="email_pwd"
          v-validate="'required'"
          :danger="hasError('email_pwd')"
          :danger-text="errorText('email_pwd')"
          :success="isSuccess('email_pwd')"
          name="email_pwd"/>
        <vs-input
          label="Código de 6 dígitos de tu segundo factor de autenticación"
          type="text"
          class="w-full mb-5"
          v-model.lazy="email_code"
          name="email_code"
          v-validate="'required|numeric'"
          :danger="hasError('email_code')"
          :danger-text="errorText('email_code')"
          :success="isSuccess('email_code')"
          :min="6"
          :max="6"
          autocomplete="off"/>
        <vs-button :disabled="!completeEmailForm || $isLoading" @click="doUpdateEmail" color="primary" class="mr-5 mt-2">Enviar</vs-button>
        <vs-button @click="closeEmailFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cancelar</vs-button>
      </div>
    </vs-popup>

    <!-- cancellation account invalid popup -->
    <vs-popup :active.sync="cancellationAccountNotValid" title="Cierre de cuenta">
      <div class="p-3 pt-1">
        <div class="vx-row">
          <div class="vx-col w-full">
            <h5>No es posible proceder con el cierre de cuenta.</h5>
            <p class="mt-4 mb-5">
              No es posible iniciar el proceso de cierre de tu cuenta, debido a que existen una o más condiciones que lo impiden:
            </p>

            <p class="bold mb-3" v-for="(validation, idx) in cancellationAccountValidations" :key="idx">
              - {{validation}}
            </p>
          </div>
          <div class="vx-col w-full mt-3">
            <div class="flex items-center flex-grow justify-left mt-1">
              <vs-button color="dark" @click.stop="cancellationAccountNotValid = false" >Finalizar</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script lang="js">
import {mapState} from "vuex";
import dateHelper from "@mixins/dateHelper";
import userComplianceHelper from "@/helpers/userComplianceHelper";

export default  {
  mixins:[dateHelper,userComplianceHelper],
  async mounted() {
    this.isMounted = false
    this.showLoading(true);
    await this.getUserData();
    this.showLoading(false);
    this.isMounted = true
  },
  data() {
    return {
      email: null,
      new_email: {
        email: '',
        password: '',
        code: '',
      },
      alias: '',
      email_error: '',
      loading_email: false,
      email_confirm_error: '',
      general_error: '',
      edit_email: false,
      set_alias: false,
      loading_alias: false,
      writing: true,
      alias_pwd: null,
      alias_error: '',

      isMounted: false,
      failed: false,
      hasAlias: false,
      showPasswordFormPrompt: false,
      modelFormPromptIndication: "Escribe tu contraseña actual para confirmar",

      showPasswordEmailFormPrompt: false,

      userData: {},
      sent: false,
      editEmail: false,
      email_pwd: null,
      email_code: null,

      impactaMasLevel: null,
      investmentPlan: null,
      factorEnabled: null,
      cancellationAccountNotValid: null,
      cancellationAccountValidations: [],
    }
  },
  watch:{
    edit_email: function(){
      this.new_email.password = '';
      this.new_email.email = this.email;
      this.new_email.code = '';
      this.email_error = '';
      this.email_confirm_error = '';
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    completeModelForm(){
      return this.alias_pwd != null;
    },
    completeEmailForm(){
      return this.email_pwd != null && this.email_code.length === 6;
    },
    userHasCancellationRequest(){
      return this.roleGeneralData.cancellationAccountRequest !== null;
    },
    userCancellationDate(){
      if(this.roleGeneralData.cancellationAccountRequest !== null){
        return this.explicitDateFormat(this.roleGeneralData.cancellationAccountRequest);
      }
    },
    userCancellationLimit(){
      if(this.roleGeneralData.cancellationAccountLimit !== null){
        return this.regularDateFormat(this.roleGeneralData.cancellationAccountLimit);
      }
    }
  },
  methods: {
    async getUserData(){
      try {
        const res2 = await axios.get(`api/perfil/solar-id/${this.UserId}`);
        this.userData = res2.data;
        this.alias = this.userData.alias;
        this.hasAlias = (this.alias != null && this.alias !== "");
        this.email = this.userData.email;
        this.investmentPlan = this.userData.plan;
        this.impactaMasLevel = this.userData.level;
        this.factorEnabled = this.userData.factorEnabled;
      }
      catch (error) {
        this.warn(error);
      }
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    async saveAliasRequest(){
      this.sent = true;
      let r = await this.$validator.validate('alias');
      if(!r){
        return;
      }
      await this.testAlias();
    },
    async testAlias(){
      try {
        let res = await axios.post('/api/perfil/alias/test', {alias: this.alias});
        if(res.data == true){
          this.openModelFormPrompt();
        }
        else {
          this.errorNotifDialog("No es posible guardar tu alias", "El alias que has elegido no se encuentr disponible, por favor intenta con otro.");
        }
      }
      catch (error) {
        this.failedOperationNotif();
      }
    },
    async confirmAliasChange(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Actualizar alias',
        text: `Confirma que deseas cambiar el alias de tu cuenta`,
        acceptText: "Confirmar",
        cancelText: 'Cancelar',
        accept: this.openModelFormPrompt
      });
    },
    async doUpdateAlias(){
      this.showLoading(true)
      try {
        await axios.post('/api/perfil/alias/set', {alias: this.alias,password: this.alias_pwd});
        this.hasAlias = true;
        this.closeModelFormPrompt();
        await this.getUserData();
        this.successNotifDialog("Operación exitosa", "Tu nuevo alias ha sido registrado correctamente. " +
          "A partir de ahora podrás utilizarlo para iniciar sesión en la plataforma.");
      }
      catch (error) {
        this.closeModelFormPrompt();
        if(error.response && error.response.data && error.response.data.message){
          this.errorNotifDialog("Contraseña incorrecta", "La contraseña ingresada es incorrecta, por lo que no se ha podido generar tu alias. " +
            "Revisa tus credenciales e intenta de nuevo,");
        }
        else {
          this.failedOperationNotif();
        }
      }
      this.showLoading(false)
    },
    async checkEditEmail(){
      if(this.factorEnabled){
        this.editEmail = true;
      }
      else {
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Actualizar correo electrónico",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
      }
    },
    cancelEditEmail(){
      this.editEmail = false;
      this.email = this.userData.email;
    },
    openModelFormPrompt() {
      this.alias_pwd = null;
      this.showPasswordFormPrompt = true;
    },
    closeModelFormPrompt() {
      this.showPasswordFormPrompt = false;
      this.alias_pwd = null;
    },

    async editEmailRequest(){
      let r = await this.$validator.validate('email');
      if(!r){
        return;
      }
      await this.testEmail();
    },
    async testEmail(){
      try {
        let res = await axios.post('/api/login/mail', {email: this.email});
        if(res.data){
          this.errorNotif("No es posible actualizar", "Este correo electrónico ya existe, por favor intenta con otro", 8000);
        }
        else {
          this.openEmailFormPrompt();
        }
      }
      catch (error) {
        this.failedOperationNotif();
      }
    },
    async doUpdateEmail(){
      this.showLoading(true)
      try {
        await axios.post('/api/perfil/email', {email: this.email, password: this.email_pwd, code: this.email_code});
        this.editEmail = false;
        this.successNotifDialog('Operación exitosa', 'Tu correo electrónico ha sido actualizado correctamente.');
      }
      catch (error) {
        if(error.response && error.response.data && error.response.data.message){
          this.errorNotifDialog("Error al actualizar", error.response.data.message);
        }
        else {
          this.failedOperationNotif();
        }
      }
      this.closeEmailFormPrompt();
      this.showLoading(false)
    },
    openEmailFormPrompt() {
      this.email_pwd = null;
      this.email_code = null;
      this.showPasswordEmailFormPrompt = true;
    },
    closeEmailFormPrompt() {
      this.email_pwd = null;
      this.email_code = null;
      this.showPasswordEmailFormPrompt = false;
    },
    async goTo2FaPage(){
      await this.$router.push({name: 'auth2FaSolicitante'});
    },
    async closeAccountRequest(){
      // solicitar datos de validacion de cierre de cuenta
      this.showLoading(true)
      try {
        const res = await axios.get(`api/v1/applicant/${this.ApplicantId}/checkAccountCancellationConditions`);
        const hasTrx = res.data.hasTrx;
        const activeProjsCount = res.data.activeProjsCount;
        const finalBalance = res.data.finalBalance;
        let validations = [];
        if(finalBalance > 0){
          validations.push('Aún tienes saldo en tu cuenta. No debes tener saldo en tu cuenta para poder cancelar tu contrato. Puedes retirarlo si así lo deseas.');
        }
        if(activeProjsCount > 0){
          validations.push('Aún cuentas con proyectos que están activos, es decir, que están en proceso de fondeo, en instalación o en proceso de cobranza.');
        }
        if(hasTrx){
          // PANEL DE CUMPLIMIENTO
          const requirements = await this.getComplianceRequirements(this.UserTags);
          if(requirements.length > 0){
            validations.push('Para que puedas cerrar la cuenta es necesario primero atender los pendientes del Panel de Cumplimiento.');
          }
        }
        if(validations.length > 0){
          this.cancellationAccountValidations = validations;
          this.cancellationAccountNotValid = true;
        }
        else {
          let mssg = 'Confirma que deseas proceder con el cierre de tu cuenta en RedGirasol.' +
            '\n\nA partir de este momento contarás con un plazo de 30 días para poder acceder a tu cuenta y verificar movimientos, datos, etc. ' +
            'Una vez cumplidos, no podrás volver a acceder.';
          this.confirmationDialog(
            this.doCancelAccount,
            'Confirma la acción',
            mssg,
            'Confirmar'
          );
        }
      }
      catch (e) {
        this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
      }
      this.showLoading(false)
    },
    async doCancelAccount(){
      this.showLoading(true)
      try {
        let payload = {};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v1/applicant/${this.ApplicantId}/doCancelAccount`, payload);
        await this.onUserInfoUpdated();
        this.successNotifDialog('Petición de cierre de cuenta', 'El proceso de cierre de cuenta ha sido iniciado correctamente.' +
          ' Recuerda que tendrás 30 días para seguir usando tu cuenta, antes de que el acceso se revoque completamente.');
      }
      catch (e) {
        if(e && e.response && e.response.data && e.response.data.error){
          this.errorNotifDialog('Error en petición', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
      this.showLoading(false)
    },
    async revokeAccountCancellation(){
      let mssg = 'Confirma que deseas cancelar la solicitud de cierre de tu cuenta en RedGirasol.' +
        '\n\nTu cuenta se mantendrá sin cambios y podrás usarla de manera normal.';
      this.confirmationDialog(
        this.doRevokeAccountCancellation,
        'Confirma la acción',
        mssg,
        'Confirmar'
      );
    },
    async doRevokeAccountCancellation(){
      this.showLoading(true)
      try {
        let payload = {};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v1/applicant/${this.ApplicantId}/doRevokeAccountCancellation`, payload);
        await this.onUserInfoUpdated();
        this.successNotifDialog('Solicitud de cierre de cuenta cancelada', 'Podrás continuar utilizando tu cuenta de forma normal.');
      }
      catch (e) {
        if(e && e.response && e.response.data && e.response.data.error){
          this.errorNotifDialog('Error en petición', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
      this.showLoading(false)
    },
  },
}
</script>